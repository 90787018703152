import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoIcon from "../assets/icons/skypro-icon.svg";
import logoLetters from "../assets/icons/GroupSkyLetters.svg";
import logoFeel from "../assets/icons/GroupSkyfeel.svg";
import logoPortal from "../assets/icons/skypro-portal.svg";
import SimpleBar from "simplebar-react";
import VerticalLayout from "./VerticalLayouts";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import moment from "moment";
import Icon from "../Components/Common/icon";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const Sidebar = () => {
  const { t } = useTranslation();
  const selectLayoutState = (state) => state.Layout;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      leftsidbarSizeType: layout.leftsidbarSizeType,
      sidebarVisibilitytype: layout.sidebarVisibilitytype,
      layoutType: layout.layoutType,
    })
  );
  const { leftsidbarSizeType } = useSelector(selectLayoutProperties);
  const [contactSwiped, setContactSwiped] = useState(false);

  const [supportSwiped, setSupportSwiped] = useState(false);

  useEffect(() => {
    const handleOverlayClick = () => {
      document.body.classList.remove("vertical-sidebar-enable");
    };

    const verticalOverlay = document.querySelector(".vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay.addEventListener("click", handleOverlayClick);
    }

    return () => {
      if (verticalOverlay) {
        verticalOverlay.removeEventListener("click", handleOverlayClick);
      }
    };
  }, []);

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  const handleOpenMenu = () => {
    document.documentElement.setAttribute(
      "data-sidebar-size",
      leftsidbarSizeType
    );
  };

  const handleContactSwipe = () => {
    setContactSwiped(true);
  };

  const handleSupportSwipe = () => {
    setSupportSwiped(true);
  };

  useEffect(() => {
    if (contactSwiped === true) {
      setTimeout(() => {
        setContactSwiped(false);
      }, 10000);
    }
  }, [contactSwiped]);

  useEffect(() => {
    if (supportSwiped === true) {
      setTimeout(() => {
        setSupportSwiped(false);
      }, 10000);
    }
  }, [supportSwiped]);

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu ">
        <div className="navbar-brand-box">
          <Link to="/" className="logo">
            <span className="logo-lg">
              <div className="sm-skypro">
                <img
                  className="icon-skypro"
                  src={logoIcon}
                  alt=""
                  style={{ verticalAlign: "unset" }}
                />
              </div>
              <div className="logo-secundary">
                <img src={logoLetters} className="lg-skypro" alt="" />
                <img src={logoFeel} className="lg-skypro" alt="" />
              </div>
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <div className="wrapper-sidebar-content">
          <SimpleBar id="scrollbar" className="scrollbar-container">
            <Container fluid>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayout />
              </ul>
            </Container>
          </SimpleBar>
          <div className="footer-sidebar">
            <img className="portal" src={logoPortal} alt="logo" height="33" />
            <div className="content-box">
              <div className="menu-swipe-container">
                <span
                  onClick={() => {
                    handleOpenMenu();
                    handleContactSwipe();
                  }}
                  className={`divisions text-buttons-sidebar menu-icon-hover menu-swipe-swipe ${
                    contactSwiped ? "menu-swiped" : ""
                  }`}
                >
                  <div className="divisions-item">
                    <Icon icon="phoneIcon" size={[16, 16]} />
                    <span
                      className="p-2 pt-0 pb-0 footer-text"
                      style={{ minWidth: 170 }}
                    >
                      {t("CONTACT_US")}
                    </span>
                  </div>
                  <i className="mdi mdi-chevron-right icon-keep" />
                </span>

                <span
                  className={`divisions text-buttons-sidebar menu-icon-hover menu-swipe-new-content ${
                    contactSwiped ? "menu-active" : ""
                  }`}
                >
                  <a
                    className="divisions-item"
                    href="mailto:support@myskypro.com"
                  >
                    <Icon icon="phoneIcon" size={[16, 16]} />
                    <span
                      className="p-2 pt-0 pb-0 footer-text"
                      style={{ minWidth: 170 }}
                    >
                      support@myskypro.com
                    </span>
                  </a>
                  <i className="mdi mdi-chevron-right icon-keep" />
                </span>
              </div>

              <div className="menu-swipe-container">
                <span
                  onClick={() => {
                    handleOpenMenu();
                    handleSupportSwipe();
                  }}
                  className={`divisions text-buttons-sidebar menu-icon-hover menu-swipe-swipe ${
                    supportSwiped ? "menu-swiped" : ""
                  }`}
                >
                  <div className="divisions-item">
                    <Icon icon="headsetsIcon" size={[16, 16]} />
                    <span
                      className="p-2 pt-0 pb-0 footer-text"
                      style={{ minWidth: 170 }}
                    >
                      {t("CUSTOMER_SUPPORT")}
                    </span>
                  </div>
                  <i className="mdi mdi-chevron-right icon-keep" />
                </span>

                <span
                  className={`divisions text-buttons-sidebar menu-icon-hover menu-swipe-new-content ${
                    supportSwiped ? "menu-active" : ""
                  }`}
                >
                  <a className="divisions-item" href="tel:+351 308 814 002">
                    <Icon icon="headsetsIcon" size={[16, 16]} />
                    <span
                      className="p-2 pt-0 pb-0 footer-text"
                      style={{ minWidth: 170 }}
                    >
                      +351 308 814 002
                    </span>
                  </a>
                  <i className="mdi mdi-chevron-right icon-keep" />
                </span>
              </div>

              <div className="watermark lg-skypro">
                <span className="powered-span" style={{ minWidth: 150 }}>
                  {moment().year()} @ Powered by Skypro
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
