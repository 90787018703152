import React from "react";
import { Navigate } from "react-router-dom";

// IFrame
import IFrame from "../pages/IFrame";
import Colours from "../pages/Colours";
import Alerts from "../pages/Alerts";
import Products from "../pages/Articles/Products";
import TablesPage from "../pages/TablesPage/index";

//login
import Login from "../pages/Authentication/Login";
// import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import Typography from "../pages/Typography";
import BasicElements from "../pages/BasicElements";
import Buttons from "../pages/Buttons";
import Placeholder from "../pages/Placeholder";
import ArticlesDetails from "../pages/ArticlesDetails";

const authProtectedRoutes = [
  { path: "/iframe", component: <IFrame /> },
  { path: "/tables", component: <TablesPage /> },
  { path: "/colours", component: <Colours /> },
  { path: "/alerts", component: <Alerts /> },
  { path: "*", component: <Navigate to="/iframe?url=#" /> },
  { path: "/typography", component: <Typography /> },
  { path: "/products", component: <Products /> },
  { path: "/basic-elements", component: <BasicElements /> },
  { path: "/articles", component: <Products /> },
  { path: "/buttons", component: <Buttons /> },
  { path: "/placeholder", component: <Placeholder /> },
  { path: "/articlesDetails/:productId", component: <ArticlesDetails /> },
];

const publicRoutes = [
  // Authentication
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/logout", component: <Logout /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
