import React from "react";
import withRouter from "../../Components/Common/withRouter";
import Logo from "../../assets/imagesNew/my-sky-pro-footer.png";
import { useTranslation } from "react-i18next";

const ParticlesAuth = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="auth-page-wrapper pt-5">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay" />
          {/* pass the children */}
          <div
            className="d-flex flex-column justify-content-between"
            style={{
              height: "100vh",
            }}
          >
            <div>{children}</div>
            <div className="text-center " style={{ marginBottom: 16 }}>
              <img
                className="mb-2"
                src={Logo}
                alt="logo"
                style={{ width: 140, height: 31 }}
              />
              <p className="mb-1 text-muted">{t("LOGIN_RESERVED_RIGHTS")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ParticlesAuth);
