import React, { useEffect, useState, useRef } from "react";
import Table from "../../Components/Common/Table";
import TableThumbs from "../../Components/Common/Table/TableThumbs";
import { GetFullProductList } from "../../infra/requests/ProductsRequests";
import { GetB2bclientWorkgroupsDrop } from "../../infra/requests/ClientRequests";
import ButtonIcon from "../../Components/Common/Buttons/ButtonIcon";
import BaseButton from "../../Components/Common/Buttons/BaseButton";
import TextInput from "../../Components/Common/Inputs/TextInput/TextInput";
import SelectInput from "../../Components/Common/Inputs/SelectInput/SelectInput";
import { ConstructQuery } from "../../shared/logic/queries/EndpointQueries";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import StockHistoryModal from "../../Components/Common/Modals/StockHistoryModal";

const Index = () => {
  const info = useSelector((state) => state.Info);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [list, setList] = useState();
  const [search, setSearch] = useState();
  const [entity, setEntity] = useState();
  const [filters, setFilters] = useState({
    search: [],
    entity: undefined,
  });
  const [columnFilters, setColumnFilters] = useState({
    manufactoring: "",
  });
  const [entities, setEntities] = useState([]);
  const [modal_center, setmodal_center] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const headerRef = useRef(null);
  const [tableThumbs, setTableThumbs] = useState(false);
  function tog_center() {
    setmodal_center(!modal_center);
  }

  const [modalStockHistory, setModalStockHistory] = useState(false);

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (!filters.search.find((item) => item.value === search)) {
        // setFilters({
        //   ...filters,
        //   search: [...filters.search, { label: search, value: search }],
        // });
        setFilters({
          ...filters,
          search: [{ label: search, value: search }],
        });
      }
      //setSearch();
    }
  };

  const handleManufactoring = () => {
    return info?.data?.productTypes
      ?.filter((e) => e.productTypeId !== 4)
      .map((item) => ({
        value: item?.productTypeId,
        label: item?.productTypeTranslation[1].name,
      }));
  };

  const stringsArray = [
    "new",
    "manufacturing",
    "ready-to-process",
    "ready-to-ship",
    "ready-to-pick-up",
    "shiped",
    "delivered",
    "open-order",
    "closed",
    "canceled",
    "waiting",
  ];

  const getRandomString = () => {
    const randomIndex = Math.floor(Math.random() * stringsArray.length);
    return stringsArray[randomIndex];
  };

  const handelClear = () => {
    setSearch();
    setEntity();
    setFilters({
      search: [],
      entity: "",
    });
    setColumnFilters({
      manufactoring: "",
    });
  };

  useEffect(() => {
    const EntitiesRequest = async () => {
      try {
        // Fetch B2B client work groups
        const { status: groupsStatus, data: groupsData } =
          await GetB2bclientWorkgroupsDrop();
        if (groupsStatus === 200) {
          // Set entities and format result to desired format for select
          setEntities(
            groupsData.items.map((item) => ({
              label: item.name,
              value: item.b2bclientId,
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    EntitiesRequest();
  }, []);

  useEffect(() => {
    const ProductsRequest = async () => {
      try {
        setLoading(true);
        const requestFilters = {
          search: filters?.search[0]?.value
            ? filters?.search[0]?.value
            : undefined,
          productTypeId:
            columnFilters?.manufactoring !== ""
              ? columnFilters?.manufactoring
              : undefined,
          b2bclientId: filters?.entity,
        };

        const result = await GetFullProductList(
          page,
          pageSize,
          ConstructQuery(requestFilters)
        );
        if (result?.status === 200) {
          // Extract relevant information from the response JSON
          const modifiedProductList = result?.data?.items?.map(
            (item, index) => ({
              id: item?.productId,
              company: item?.company || "-",
              image: item?.productImage[0]?.image?.url,
              productName: item?.productTranslation[0]?.name || "-",
              referencecode: item?.referenceCode,
              type: item?.productType?.productTypeTranslation[0]?.name || "-",
              category: item?.mainCategory?.categoryTranslation[1]?.name || "-",
              price: "120,00",
              QTY_Avail: index > 2 ? 50 : 80,
              forecast: "100",
              forecastDate: "25·08·24",
              color: "Aubergine",
              size: 36,
              progress: Math.floor(Math.random() * 6) + 1,
              status2: getRandomString(),
              active: item?.active
                ? item?.active === true
                  ? "active"
                  : "canceled"
                : "unknown",
            })
          );

          setList(modifiedProductList);
          setTotalItems(result?.data?.totalItems);
          setLoading(false);
          setInitialLoading(false);
        } else {
          setList([]);
          setTotalItems(0);
        }
      } catch (error) {
        console.log(error);
      }
    };

    ProductsRequest();
  }, [page, pageSize, filters, columnFilters]);

  const tableColumnsAPI = {
    columns: [
      {
        type: "check",
        footer: "Total",
        align: "center",
      },
      {
        type: "image-component",
        field: "image",
        header: "Article",
        align: "flex-start",
      },
      {
        field: "company",
        header: "Company",
        align: "center",
      },
      {
        field: "type",
        header: "Manufactoring",
        columnFilter: "single",
        filterName: "manufactoring",
        filter: true,
        filters: handleManufactoring(),
        align: "center",
      },
      {
        field: "category",
        header: "Category",
        align: "center",
        footer: "180",
      },
      {
        type: "currency",
        field: "price",
        header: "Price",
        align: "flex-end",
        footer: "13515065€",
      },
      {
        type: "status-tag",
        field: "active",
        header: "Status",
        align: "center",
      },
      {
        type: "quantity-date",
        field: "forecast",
        header: "Forecast",
        align: "center",
      },
      {
        type: "number-status",
        field: "QTY_Avail",
        header: "QTY Avail.",
        align: "center",
      },
      {
        field: "color",
        header: "Color",
        align: "center",
      },
      {
        field: "size",
        header: "Size",
        align: "center",
      },
      {
        type: "badge",
        field: "status2",
        header: "Status",
        align: "flex-start",
      },
      {
        type: "battery-badge",
        field: "progress",
        header: "Progress",
        align: "center",
      },
    ],
    data: list,
    size: pageSize,
    footer: true,
    scroll: {
      scrollable: true,
      scrollableHeight: `${window.innerHeight - 360}px`,
    },
    editrow: {
      hasEdit: true,
    },
    checkboxes: {
      checked: true,
    },
  };

  const [divHeight, setDivHeight] = useState(86);

  // Function to update the height
  const updateHeight = () => {
    if (headerRef.current) {
      setDivHeight(headerRef.current.offsetHeight);
    }
  };

  // Measure the height on mount and whenever the window is resized
  useEffect(() => {
    // Measure the height when component mounts
    updateHeight();

    // Add event listener for window resize
    window.addEventListener("resize", updateHeight);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#F2F3F4",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        // overflowY: "hidden",
      }}
      className="page-content"
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          maxWidth: 1440,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!initialLoading && (
          <div
            style={{
              padding: 20,
              height: "100%",
              width: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                width: "100%",
                paddingTop: 8,
                paddingBottom: 17,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end ",
                paddingRight: 15,
                paddingLeft: 24,
                gap: 10,
                flexWrap: "wrap",
              }}
              ref={headerRef}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "flex-end",
                  flexWrap: "wrap",
                }}
              >
                <ButtonIcon
                  type="secondary"
                  color="yellow"
                  size={[15, 15]}
                  icon="treeDots"
                />
                <TextInput
                  placeholder="Type here..."
                  label="Search"
                  type="text"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={handleSearch}
                  value={search}
                  icon="searchIcon"
                  iconSize={[14, 14]}
                />
                <div style={{ minWidth: 150 }}>
                  <SelectInput
                    name="select"
                    id="select"
                    label="Company"
                    placeholder="Choose"
                    onChange={(e) => {
                      setEntity(e);
                      setFilters({ ...filters, entity: e });
                    }}
                    value={entity}
                    options={entities}
                  />
                </div>
                <BaseButton
                  type="secondary"
                  text="Clear"
                  icon="filters"
                  onClick={() => handelClear()}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  flexWrap: "wrap",
                }}
              >
                <ButtonIcon
                  type={tableThumbs ? "secondary" : ""}
                  size={[15, 15]}
                  icon="linesIcon"
                  onClick={() => setTableThumbs(false)}
                />
                <ButtonIcon
                  type={!tableThumbs ? "secondary" : ""}
                  size={[15, 15]}
                  icon="squareIcon"
                  onClick={() => setTableThumbs(true)}
                />
                <BaseButton
                  type="secondary"
                  text="Add row"
                  icon="plusLine"
                  onClick={() => tog_center()}
                />
                <BaseButton
                  text="Save"
                  onClick={() => setModalStockHistory(!modalStockHistory)}
                />
              </div>
            </div>
            <div
              style={{
                maxHeight: "calc(100% - 90px)",
                // overflow: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {!tableThumbs ? (
                <Table
                  loading={loading}
                  totalItems={totalItems}
                  currentPage={page}
                  setCurrentPage={setPage}
                  rowsPerPage={pageSize}
                  setRowsPerPage={setPageSize}
                  tableColumns={tableColumnsAPI}
                  setList={setList}
                  tableInfo={list}
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                />
              ) : (
                <TableThumbs
                  headerHeight={divHeight}
                  totalItems={totalItems}
                  tableInfo={list}
                  currentPage={page}
                  setCurrentPage={setPage}
                  rowsPerPage={pageSize}
                  setRowsPerPage={setPageSize}
                />
              )}
            </div>
            <Modal
              size="xl"
              isOpen={modal_center}
              toggle={() => {
                tog_center();
              }}
              centered
            >
              <ModalHeader className="modal-title" style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>Add row</div>
                  <div className="cursor-pointer" onClick={() => tog_center()}>
                    <ButtonIcon type="noBorder" icon="xIcon" color="black" />
                  </div>
                </div>
              </ModalHeader>

              <ModalBody className="p-5">
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <TextInput
                    label="Input 1"
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleSearch}
                    value={search}
                    iconSize={[14, 14]}
                  />
                  <TextInput
                    label="Input 2"
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleSearch}
                    value={search}
                    iconSize={[14, 14]}
                  />

                  <TextInput
                    label="Input 3"
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleSearch}
                    value={search}
                    iconSize={[14, 14]}
                  />
                  <TextInput
                    label="Input 4"
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleSearch}
                    value={search}
                    iconSize={[14, 14]}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 20,
                    }}
                  >
                    <BaseButton
                      type="primary"
                      height="small"
                      text="Add"
                      onClick={() => tog_center()}
                    />
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <StockHistoryModal
              isOpened={modalStockHistory}
              size="xl"
              toggle={() => {
                setModalStockHistory(!modalStockHistory);
              }}
              centered
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
