import {
  profileSuccess,
  profileError,
  resetProfileFlagChange,
} from "./reducer";
import { GetAuthUser } from "../../../infra/requests/BaseRequests";

export const getProfile = () => async (dispatch) => {
  try {
    const userData = await GetAuthUser();

    if (userData.status === 200) {
      dispatch(profileSuccess(userData));
    }
  } catch (error) {
    dispatch(profileError(error));
  }
};

export const resetProfileFlag = () => {
  try {
    const response = resetProfileFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
